import React from "react";
import "../copyright-section/copyright-section.css";

const CopyrightSection = () => {
  return (
    <div className="copyright">
      <p>Copyright &copy; Made By Ankit Kapoor</p>
    </div>
  );
};

export default CopyrightSection;
